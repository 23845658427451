import { useNavigate, useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { useStateContext } from "context/ContextProvider";
import axiosClient from "axios-client";
import { Priority, Progress, Role, User, initTask } from 'types'
import style from 'assets/css/Form.module.css'

interface TaskFormProps {
  taskable?: {id: string, name:string, type:string};
  closePopup?: () => void;
}

export default function TaskForm(props: TaskFormProps ) {

  const navigate = useNavigate();
  let { id } = useParams();
  const [task, setTask] = useState(initTask);

  const [team, setTeam] = useState<User[]>();

  const { setNotification, user } = useStateContext();

  const priority = Object.values(Priority);
  const progress = Object.values(Progress);
  const role = Object.values(Role);

  useEffect(() => {
    getTeam();
    if(props.taskable){ setTask({...task, taskable: props.taskable}) }
    
    // Get the task data
    if (id && !props.taskable) {
      console.log(id);
      
      axiosClient.get(`/tasks/${id}`)
        .then(({ data }) => {
          setTask({ ...data });
        })
        .catch(err => {
          const response = err.response;
          if (response) {
            setNotification(response.message, 'warning');
          }
        })
    }
  }
    , [])

    const getTeam = () => {
    
    axiosClient.get('/users', { params: { 'role': 'TeamMember' } })
      .then(({ data }) => {
        setTeam(data.data);
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          setNotification(response.message, 'warning');
        }
      })
  }

  const onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault();
    

    if (task.id) {
      axiosClient.put(`/tasks/${task.id}`, task)
        .then(() => {
          setNotification('Task was successfully updated', 'success');
        })
        .catch(err => {
          const response = err.response;
          if (response) {
            setNotification(response.message, 'warning');
          }
        })
    } else {
      axiosClient.post('/tasks', task, {headers: {
        'Content-Type': 'multipart/form-data',
      },})
        .then(() => {
          setNotification('Task was successfully created', 'success');
        })
        .catch(err => {
          const response = err.response;
          if (response) {
            setNotification(response.message, 'warning');
          }
        })
    }
    props.closePopup && props.closePopup();
  }

  return (
    <>
      <form className={`${style.form}`} onSubmit={onSubmit}>
        <div className={`${style.title}`}>
          {task.id ? `Update Task: ${task.task_id}` : 'New Task'}
        </div>
        <div className={`${style.container}`}>

          <div className={`${style.input_container} ${style.span2}`}>
            <label className={`${style.label}`} htmlFor="title">Title</label>
            <input className={`${style.input}`} type="text" name="title" id="title" value={task.title} onChange={ev => setTask({ ...task, title: ev.target.value })} required />
          </div>

          {(user?.roles.includes(Role.Admin) || user?.roles.includes(Role.Manager)) &&
            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="assignable">Assign To</label>
              <select name="assignable" id="assignable" value={task.assignable.type + '_' + task.assignable.id}
                onChange={ev => setTask(
                  {
                    ...task, assignable: {
                      id: ev.target.value.split('_')[1],
                      name: '',
                      type: ev.target.value.split('_')[0]
                    }
                  })
                }>
                <option value={''} >Assign to</option>
                {role.map((option) => (
                  <option key={option} value={'role_' + option}>
                    {option}
                  </option>
                ))}
                {team?.map((option) => (
                  <option key={option.id} value={'user_' + option.id} >
                    {option.firstname} {option.lastname}
                  </option>
                ))}
              </select>
            </div>
          }
          
          {(user?.roles.includes(Role.Admin) || user?.roles.includes(Role.Manager)) &&
            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="duedate">Due date</label>
              <input className={`${style.input}`} type="date" name="duedate" id="duedate" value={task.duedate} onChange={ev => setTask({ ...task, duedate: ev.target.value })} />
            </div>
          }

          {(user?.roles.includes(Role.Admin) || user?.roles.includes(Role.Manager)) &&
            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="priority">Priority *</label>
              <select name="priority" id="priority" value={task.priority} onChange={ev => setTask({ ...task, priority: ev.target.value as Priority })} required>
                <option value={''} >Select a priority</option>
                {priority.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          }          

          {(user?.roles.includes(Role.Team)) &&
            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="progress">Status *</label>
              <select name="progress" id="progress" value={task.progress} onChange={ev => setTask({ ...task, progress: ev.target.value as Progress })} required>
                <option value={''} >Select a progress</option>
                {progress.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          }

          <div className={`${style.input_container}  ${style.span2}`}>
            <label className={`${style.label}`} htmlFor="file">File</label>
            <input type="file" className={`${style.input}`} name="file" id="file" onChange={ev => setTask({ ...task, file:  ev.target.files?.[0] as File})}/>
          </div>

          <div className={`${style.input_container}  ${style.span2}`}>
            <label className={`${style.label}`} htmlFor="desc">Description</label>
            <textarea className={`${style.input}`} name="desc" id="desc" value={task.desc} onChange={ev => setTask({ ...task, desc: ev.target.value })} />
          </div>
        </div>
        <button type="submit" className={`${style.button} ${style.submit}`}>Save</button>
        {!props.closePopup && 
        <button type='reset' className={`${style.button} ${style.cancel}`} onClick={() => navigate(-1)}>
        Cancel
      </button>
        }
        
      </form>
    </>
  )
}
