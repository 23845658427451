import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { Column, Role, Task } from "types";
import { CSS } from "@dnd-kit/utilities";
import { useMemo } from "react";
import TaskCard from "./TaskCard";
import styles from './Kanban.module.css'
import { ReactComponent as IcoOptions } from "assets/icons/options.svg";
import { useStateContext } from "context/ContextProvider";

interface Props {
  column: Column;
  tasks: Task[];
}

function ColumnContainer({
  column,
  tasks,
}: Props) {

  const { user } = useStateContext();
  const disableDrag = user?.roles[0]===Role.User;

  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={`${styles.column_dragging}`}
      ></div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={styles.column}
    >
      {/* Column title */}
      <div {...attributes} {...listeners} className={styles.column_title}>
        <div>
          {column.title}
        </div>
        <div>
          {tasks.length}
        </div>
        <IcoOptions className={`${styles.options}`} />
      </div>

      {/* Column task container */}
      <div className={`${styles.tasks_container} ${disableDrag ? styles.disableDragTask:''}`}>
        <SortableContext items={tasksIds} disabled={disableDrag}>
          {tasks.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
            />
          ))}
        </SortableContext>
      </div>
    </div>
  );
}

export default ColumnContainer;