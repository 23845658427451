import { useNavigate, useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { Client, Status, Subscription, User } from 'types';
import style from 'assets/css/Form.module.css'
import { ReactComponent as IcoAdd } from 'assets/icons/add.svg'
import { useStateContext } from 'context/ContextProvider';
import axiosClient from 'axios-client';

export default function ClientForm() {

  const navigate = useNavigate();
  let { id } = useParams();

  const [client, setClient] = useState<Client>({} as Client);
  const [managers, setManager] = useState<User[]>([]);
  const { setNotification } = useStateContext();

  // Get enum
  const statusVal = Object.values(Status);
  const subsription = Object.values(Subscription);

  useEffect(() => {
    getManagers();
    if (id) {
      getClient();
    }
  }, []);

  const getClient = () => {

    axiosClient.get('/clients/' + id)
      .then(({ data }) => {

        setClient(data);
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          setNotification(response.message, 'warning');
        }
      })
  }

  const getManagers = () => {
    axiosClient.get('/users', { params: { 'role': 'Manager' } })
      .then(({ data }) => {
        setManager(data.data);

      })
      .catch(err => {
        const response = err.response;
        if (response) {
          setNotification(response.message, 'warning');
        }
      })
  }

  const onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault();

    if (client.id) {
      axiosClient.put(`/clients/${client.id}`, client)
        .then(({ data }) => {
          setNotification('Client was successfully updated', 'success');
          //navigate('/clients')
        })
        .catch(err => {
          const response = err.response;
          if (response) {
            setNotification(response.message, 'warning');
          }
        })
    } else {
      axiosClient.post('/clients', client)
        .then(() => {
          setNotification('Client was successfully created', 'success')
          //navigate('/clients')
        })
        .catch(err => {
          const response = err.response;
          if (response) {
            setNotification(response.message, 'warning');
          }
        })
    }
  }

  return (
    <>
      <form className={`${style.form}`} onSubmit={onSubmit}>
        <div className={`${style.title}`}>
          {client.id ? `Update client: ${client.name}` : 'New client'}
        </div>

        <div style={{ display: 'inline-flex', width: '100%' }}>
          <div style={{ marginRight: '15px' }}>
            <div style={{ width: 'max-content' }}>Client information</div>
            <div className={`${style.image}`}>
              <div className={`${style.initial}`}>
                MM
              </div>
              <img className={`${style.preview}`} alt="Preview" />
            </div>
            <button className={`${style.upload}`}>
              <IcoAdd className={`${style.plus}`} />
              Upload Logo
            </button>
          </div>


          <div className={`${style.container}`}>

            <div></div>
            <div>Contact information</div>

            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="name">Client Name *</label>
              <input className={`${style.input}`} type="text" name="name" id="name" value={client.name || ''} onChange={ev => setClient({ ...client, name: ev.target.value })} required />
            </div>

            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="website">Website</label>
              <input className={`${style.input}`} type="url" name="website" id="website" value={client.website || ''} onChange={ev => setClient({ ...client, website: ev.target.value })} />
            </div>

            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="ice">ICE</label>
              <input className={`${style.input}`} type="text" name="ice" id="ice" value={client.ice || ''} onChange={ev => setClient({ ...client, ice: ev.target.value })} />
            </div>

            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="email">Email *</label>
              <input className={`${style.input}`} type="email" name="email" id="email" value={client.email || ''} onChange={ev => setClient({ ...client, email: ev.target.value })} required />
            </div>

            <div style={{ display: "grid", grid: 'auto / 1fr 1fr', gap: 10 }}>
              <div className={`${style.input_container}`}>
                <label className={`${style.label}`} htmlFor="subscription">Subscription *</label>
                <select className={`${style.input}`} name="subscription" id="subscription" value={client.subscription || ''} onChange={ev => setClient({ ...client, subscription: ev.target.value as Subscription })} required>
                  <option >Select a subscription plan</option>
                  {subsription.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${style.input_container}`}>
                <label className={`${style.label}`} htmlFor="subDate">Subscription date</label>
                <input className={`${style.input}`} type="date" name="subDate" id="subDate" value={client.subs_date || ''} onChange={ev => setClient({ ...client, subs_date: ev.target.value })} />
              </div>
            </div>

            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="phone">Phone number</label>
              <input className={`${style.input}`} type="tel" name="phone" id="phone" value={client.phone || ''} onChange={ev => setClient({ ...client, phone: ev.target.value })} />
            </div>

            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="status">Status *</label>
              <select className={`${style.input}`} name="status" id="status" value={client.status || ''} onChange={ev => setClient({ ...client, status: ev.target.value as Status })} required>
                <option value={''} >Select a status</option>
                {statusVal.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className={`${style.input_container}`}>
              <label className={`${style.label}`} htmlFor="contract">Contract *</label>
              <input className={`${style.input}`} type="file" accept="application/pdf" name="contract" id="contract" />
            </div>
          </div>
        </div>

        <div className={`${style.input_container}`}>
          <label className={`${style.label}`} htmlFor="agent">Company agent</label>
          <option value={''} >Select a manager</option>
          <select className={`${style.input} ${style.agent}`}
            name="agent" id="agent"
            value={client?.manager?.id}
            onChange={ev => setClient({ ...client, manager: { id: ev.target.value, name: 'test' } })}>
            <option value=''>Select a Manager</option>
            {managers.map((manager) => (
              <option
                key={manager.id}
                value={manager.id}>
                {manager.firstname} {manager.lastname}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className={`${style.button} ${style.submit}`}>Save</button>
        <button type='reset' className={`${style.button} ${style.cancel}`} onClick={() => navigate(-1)}>
          Cancel
        </button>


      </form>

    </>
  )
}