import { useNavigate, useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { useStateContext } from "context/ContextProvider";
import axiosClient from "axios-client";
import { Role, Status, initUser } from 'types'
import style from 'assets/css/Form.module.css'

export default function UserForm() {

  const navigate = useNavigate();
  let { id } = useParams();
  const [user, setUser] = useState(initUser)

  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setNotification } = useStateContext();

  const roles = Object.values(Role);
  const statusVal = Object.values(Status);

  useEffect(() => {
    if (id) {
      setLoading(true)
      axiosClient.get(`/users/${id}`)
        .then(({ data }) => {
          setLoading(false);
          setUser({...data});
        })
        .catch(() => {
          setLoading(false)
        })
    }
    }
    , [])

  

  const onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault();

    if (user.id) {
      axiosClient.put(`/users/${user.id}`, user)
        .then(() => {
          setNotification('User was successfully updated','success');
        })
        .catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors)
            setNotification(response.data.message,'fail');
          }
        })
    } else {
      axiosClient.post('/users', user)
        .then(() => {
          setNotification('User was successfully created','success');
        })
        .catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors)
          }
        })
    }
  }

  return (
    <>
      <div>
      </div>
      <form className={`${style.form}`} onSubmit={onSubmit}>
        <div className={`${style.title}`}>
          {user.id ? `Update User: ${user.firstname} ${user.lastname} ` : 'New User'}
        </div>
        <div className={`${style.container}`} style={{ width: '50%', margin: 'auto' }}>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="first">First name *</label>
            <input className={`${style.input}`} type="text" name="first" id="first" value={user.firstname} onChange={ev => setUser({ ...user, firstname: ev.target.value })} required />
          </div>
          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="last">Last name *</label>
            <input className={`${style.input}`} type="text" name="last" id="last" value={user.lastname} onChange={ev => setUser({ ...user, lastname: ev.target.value })} />
          </div>

          <div className={`${style.input_container} ${style.span2}`}>
            <label className={`${style.label}`} htmlFor="email">Email *</label>
            <input className={`${style.input}`} type="email" name="email" id="email" value={user.email} onChange={ev => setUser({ ...user, email: ev.target.value })} required />
          </div>



          <div className={`${style.input_container} ${style.span2}`}>
            <label className={`${style.label}`} htmlFor="role">Role *</label>
            <select name="role" id="role" value={user.roles[0]} onChange={ev => setUser({ ...user, roles: [ev.target.value] as Role[] })} required>
              <option value={''} >Select a role</option>
              {roles.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={`${style.input_container} ${style.span2}`}>
            <label className={`${style.label}`} htmlFor="status">Status *</label>
            <select name="status" id="status" value={user.status} onChange={ev => setUser({ ...user, status: ev.target.value as Status })} required>
              <option value={''} >Select a status</option>
              {statusVal.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>


        </div>
        <button type="submit" className={`${style.button} ${style.submit}`}>Save</button>
        <button type='reset' className={`${style.button} ${style.cancel}`} onClick={() => navigate(-1)}>
          Cancel
        </button>
      </form>
    </>
  )
}
