import { Routes, Route } from "react-router-dom"
import Clients from "./index/Clients";
import ClientForm from "./form/ClientForm";
import ClientView from "./show/ClientView";

const ClientsRoutes = () => {
    return (
      <Routes>
        <Route path='/'>
            <Route index element={<Clients />} />
            <Route path='new' element={<ClientForm key='clientCreate'/>} />
            <Route path=':id' element={<ClientForm key='clientUpdate'/>} />
            <Route path='view/:id' element={<ClientView key='clientView'/>} />
        </Route>
      </Routes>
    );
  };

export default ClientsRoutes
