import React, { useEffect, useState } from 'react'
import axiosClient from 'axios-client';
//import { Link } from 'react-router-dom';
import { useStateContext } from 'context/ContextProvider';
import AddButton from 'components/Buttons/Add/AddButton';
import style from 'assets/css/Table.module.css'
import Filter from 'components/Filter'
import { Client, Id, Status, Subscription } from 'types';
import { useToggleMenu } from 'hooks/useToggleMenu';
import Tag from 'components/Buttons/Tag';
import StatusMenu from 'components/Buttons/StatusMenu';
import { useNavigate } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import usePagination from 'hooks/usePagination';

const Clients: React.FC = () => {

  const [clients, setClients] = useState<Client[]>([]);
  const { setNotification } = useStateContext();
  const { isOpen, toggleMenu, closeMenu } = useToggleMenu();
  const navigate = useNavigate();

  // Get enums
  const subs = Object.values(Subscription);
  const statusVal = Object.values(Status);
  // Datalist suggestions
  const suggestions: (Subscription | Status)[] = [...statusVal, ...subs];

  // Pagination Hook
  const itemsPerPage = 10;
  const { currentPage, totalPages, handlePageChange, getCurrentPageData } = usePagination(clients, itemsPerPage);

  useEffect(() => {
    getClients([]);
  }, []);

  const getClients = (filters:string[]) => {
    
    axiosClient.get('/clients',{
      params:{
        'filters':filters
      }})
      .then(({ data }) => {
        
        setClients(data.data);
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          setNotification(response.message, 'warning');
        }
      })
  }

  const updateObjectStatus = (objectId:Id, newStatus:Status) => {
    // Find the account by its ID
    const updatedClients = clients?.map((client) => {
      if (client.id === objectId) {
        // Update the status of the account with the provided ID
        return { ...client, status: newStatus };
      }
      return client;
    });

    // Update the state with the modified accounts array
    setClients(updatedClients);
  };

  return (
    <>
      <div className={style.page_title}>
        <div className={style.label}>
          Clients
        </div>
        <div className={style.count}>{clients?.length} clients</div>
        { (clients && clients.length > 0) &&
          <div style={{ marginLeft: 'auto' }}>
            <AddButton text='Client' />
          </div>
        }
      </div>
      <div className={style.box}>

      <Filter setFilters={getClients} suggestions={suggestions.sort()}/>

        <table className={style.table} style={{ border: !clients?.length ? '0px' : '' }}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Subscription</th>
              <th>Manager</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {getCurrentPageData()?.map((c) => (
              <tr key={c.id} onDoubleClick={()=>navigate('view/'+c.id)}>
              <td>{c.name}</td>
    
              <td>
                <Tag name={c.subscription}/>
              </td>
              
              <td>
                { c.manager.name && 
                  <div className={style.manager}>
                    {c.manager.name}
                  </div>
                }
                
              </td>
              <td className={style.mail}>{c.email}</td>
              <td onClick={(event) => toggleMenu(c.id, event)}>
                <Tag name={c.status} />
                {isOpen === c.id && (
                  <StatusMenu showMenu={true} onClose={closeMenu} object={c} updateStatus={updateObjectStatus}/>
                )}
              </td>
            </tr>
            ))}
          </tbody>
        </table>

        {!clients?.length && (
          <div className={style.no_record}>
            <div className={style.text_wrapper}>No client yet! Please click on the button bellow to add new client.</div>
            <AddButton text='Client'/>
          </div>
        )}

        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}/>
      </div>
    </>

  )
}

export default Clients