import { Routes, Route } from "react-router-dom"
import Tasks from "./index/Tasks";
import TaskView from "./show/TaskView";
import TaskForm from "./form/TaskForm";

const TasksRoutes = () => {
    return (
      <Routes>
        <Route path='/'>
          <Route index element={<Tasks />} />
          <Route path='view/:id' element={<TaskView key='clientView' />} />
          <Route path='new' element={<TaskForm key='taskCreate' />} />
          <Route path=':id' element={<TaskForm key='taskUpdate' />} />
        </Route>
      </Routes>
    );
  };

export default TasksRoutes
