import React from 'react'
import { ReactComponent as IcoDM } from 'assets/icons/Logo.svg'
import style from './Auth.module.css'

//import { useStateContext } from 'src/context/ContextProvider'
//import axiosClient from 'src/axios-client'


let message = 'You should receive an email shortly with further instructions.';

const Register = () => {

  //const {setUser, setToken} = useStateContext();
  //const [errors, setErrors] = useState(null);

  return (
    <form className={`${style.form}`}>
      <IcoDM className={`${style.logo}`} />
      <div className={`${style.row} ${style.message}`} style={{ display: message === "" ? 'none' : '' }}>
        { message }
      </div>

      <div className={`${style.row}`}>
        <input type='text' placeholder='First name' className={`${style.input} ${style.name}`} />
        <input type='text' placeholder='Last name' className={`${style.input} ${style.name}`} />
      </div>
      
      <input type='email' placeholder='Email' className={`${style.input}`} />
      <input type='tel' placeholder='Phone number' className={`${style.input}`} />
      
      <input type="submit" value="Submit" className={`${style.submit}`} />
    </form>
  )
  
}

export default Register
