import { useMemo, useState } from "react";
import { Column, Id, Progress, Task } from "types";
import ColumnContainer from "./ColumnContainer";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./TaskCard";
import style from './Kanban.module.css'
import { useStateContext } from "context/ContextProvider";
import axiosClient from "axios-client";

const defaultCols: Column[] = [
  {
    id: Progress.New,
    title: "New",
  },
  {
    id: Progress.InProgress,
    title: "Work in progress",
  },
  {
    id: Progress.OnHold,
    title: "On hold",
  },
  {
    id: Progress.Done,
    title: "Done",
  },
  {
    id: Progress.Canceled,
    title: "Canceled",
  },
  {
    id: Progress.Archived,
    title: "Archived",
  },
  {
    id: Progress.Unassigned,
    title: "Unassigned",
  },
  
];

interface KanbanBoardProps {
  tasks: Task[],
}

const KanbanBoard:React.FC<KanbanBoardProps> = (KanbanBoardProps) => {

  const [columns, setColumns] = useState<Column[]>(defaultCols);
  
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const [tasks, setTasks] = useState<Task[]>(KanbanBoardProps.tasks);

  const [activeColumn, setActiveColumn] = useState<Column | null>(null);

  const [activeTask, setActiveTask] = useState<Task | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const { setNotification } = useStateContext();

  const handleUpdateProgress = (id: Id, newStatus : Progress) => {
    axiosClient.patch(`/tasks/${id}/progress`, {progress:newStatus})
    .then(({ data }) => {
        setNotification('Task was successfully updated','success');
    })
    .catch(({ errors }) => {
        setNotification('There was a problem','warning');
        console.log(errors);
    })
}

  return (
    <div className={`${style.board}`}>
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <div className={`${style.dndcontext}`}>
            <SortableContext items={columnsId}>
              {columns.map((col) => (
                <ColumnContainer
                  key={col.id}
                  column={col}
                  tasks={tasks.filter((task) => task.progress === col.id)}
                />
              ))}
            </SortableContext>
        </div>

        {createPortal(
          <DragOverlay>
            {activeColumn && (
              <ColumnContainer
                column={activeColumn}
                tasks={tasks.filter(
                  (task) => task.progress === activeColumn.id
                )}
              />
            )}
            {activeTask && (
              <TaskCard 
                task={activeTask}
              />
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );

  function onDragStart(event: DragStartEvent) {
    
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    //Drag column
    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;
    
    const isActiveAColumn = active.data.current?.type === "Column";
    if (!isActiveAColumn) return;

    // Re-order the column array
    setColumns((columns) => {
      const activeColumnIndex = columns.findIndex((col) => col.id === activeId);

      const overColumnIndex = columns.findIndex((col) => col.id === overId);

      return arrayMove(columns, activeColumnIndex, overColumnIndex);
    });
  }

  function onDragOver(event: DragOverEvent) {
    // Task drag
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    // Im dropping a Task over another Task
    // Re-order Tasks within the column
    if (isActiveATask && isOverATask) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);

        if (tasks[activeIndex].progress !== tasks[overIndex].progress) {
          // Give the task the new ColumnID
          tasks[activeIndex].progress = tasks[overIndex].progress;
          handleUpdateProgress(tasks[activeIndex].id,tasks[activeIndex].progress);
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";
    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);

        tasks[activeIndex].progress = overId as Progress;
        //console.log("DROPPING TASK OVER COLUMN", tasks[activeIndex]);
        handleUpdateProgress(tasks[activeIndex].id,tasks[activeIndex].progress)
        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }
  }
}

export default KanbanBoard;