import React from "react";
import style from "./AddButton.module.css";
import { ReactComponent as IcoAdd } from 'assets/icons/add.svg'
import { useNavigate } from "react-router-dom";


interface AddButtonProps {
  text:string,
}

const AddButton: React.FC<AddButtonProps> = (props) => {

  const navigate = useNavigate();

  return (
    <>
      <button className={style.add_client_btn} onClick={() => navigate('new')}>
        <IcoAdd className={style.addicon} />
        <div className={style.text_wrapper}>Add {props.text}</div>
      </button>
    </>
  );
};

export default AddButton;