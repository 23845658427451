import React from 'react'
import { ReactComponent as IcoDM } from 'assets/icons/Logo.svg'
import style from './Auth.module.css'

//import { useStateContext } from 'src/context/ContextProvider'
//import axiosClient from 'src/axios-client'


let message = 'You should receive an email shortly with further instructions.';

const ChangePW = () => {

  //const {setUser, setToken} = useStateContext();
  //const [errors, setErrors] = useState(null);





  return (
    <form className={`${style.form}`}>
      <IcoDM className={`${style.logo}`} />
      <input type='password' placeholder='New password' className={`${style.input}`} />
      <input type='password' placeholder='Repeat password' className={`${style.input}`} />
      <div className={`${style.row} ${style.message}`} style={{ display: message === "" ? 'none' : '' }}>
      { message }
      </div>
      <input type="submit" value="Submit" className={`${style.submit}`} />
    </form>
  )
}

export default ChangePW
