import React, { useState } from "react";
import style from "./Filter.module.css";
import Tag from "components/Buttons/Tag";

interface FilterProps {
  setFilters: (keywords:string[])=>void,
  suggestions?: string[],
}

const Filter: React.FC<FilterProps> = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') { // Check if "Enter" key is pressed
      event.preventDefault(); // Prevent the default form submission behavior
      
      const newTag = event.currentTarget.value.trim(); // Get the trimmed value from the input
      
      if (newTag !== '' && !tags.includes(newTag)) {
        // Add the value as a tag to the tags array
        setTags(prevTags => {
          const updatedTags = [...prevTags, newTag];
          props.setFilters(updatedTags);
          return updatedTags;
        });
        setInputValue(''); // Clear the input field after adding the tag
      }
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    
    setTags(prevArray => {
      // Filter out the tag to delete from the previous tags array
      const updatedTags = prevArray.filter(item => item !== tagToDelete);
      
      props.setFilters(updatedTags);
      // Update the local state with the filtered tags
      return updatedTags;
    });
  };

  return (
    <div className={`${style.filter}`}>
      <div className={`${style.tag_box}`}>
        {tags.map((tag, index) => (
          <Tag key={index} name={tag} deleteTag={handleDeleteTag} />
        ))}

      </div>
      <input list="tags" type="text" placeholder="Filter" className={`${style.input}`} value={inputValue} onKeyDown={handleKeyDown} onChange={ev => setInputValue(ev.target.value)} />

      {/* Displaying the suggestions */}
      {props.suggestions &&
        <datalist id="tags">
          {props.suggestions.map((suggestion, index) => (
            <option key={index} value={suggestion} onClick={() => setInputValue(suggestion)} >{suggestion.charAt(0).toUpperCase() + suggestion.slice(1)}</option>
          ))}
        </datalist>
      }

    </div>
  );
};

export default Filter;