import React from 'react'
import { ReactComponent as Ico404 } from 'assets/icons/error404.svg';
import style from 'assets/css/Error.module.css'
import { useNavigate } from 'react-router-dom';

const Page404 = () => {

  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <div className={style.textContainer}>
        <div style={{fontSize:'40px'}}>Oops...</div>
        <div style={{fontSize:'32px'}}>Page not found</div>
        <div style={{fontSize:'16px',color:'#4B4B4B'}}>This Page doesn’t exist or was removed! <br/> We suggest you back.</div>
        <button className={style.backButton} onClick={()=> navigate(-1)}>Back</button>
      </div>
      <Ico404 />
    </div>    
  )
}

export default Page404
