import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import style from 'assets/css/View.module.css'
import { ReactComponent as IcoPic } from 'assets/icons/picture.svg';
import { ReactComponent as IcoEdit } from 'assets/icons/edit.svg';
import { Client } from 'types';
import { useStateContext } from "context/ContextProvider";
import axiosClient from "axios-client";
import Tag from "components/Buttons/Tag";
import Popup from "components/Popup/Popup";
import TaskForm from "views/tasks/form/TaskForm";

export default function ClientView() {

  let { id } = useParams();
  const navigate = useNavigate();

  const { setNotification } = useStateContext();

  // Button Menu
  const [showOption, setShowOption] = useState(false);
  const backdropRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent ) => {
    if (backdropRef.current && !backdropRef.current.contains(event.target as HTMLDivElement)) {
      setShowOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [backdropRef]);

  // PopUp Form
  const [ showPopup, setShowPopup] = useState(false);
  const handlePopupTask = () => {
    setShowOption(false);
    setShowPopup(!showPopup);
  }

  // Get client from api
  const [client, setClient] = useState<Client>();
  useEffect(()=>{
    axiosClient.get(`/clients/${id}`)
        .then(({ data }) => {
          setClient({...data});
        })
        .catch((err) => {
          const response = err.response.data;
          setNotification(response.message,'warning');
        })
  },[]);
  
  if(!client){
    return <></>
  }

  return (
    <>
        <div className={`${style.title}`}>
          <div>Client’s profile </div>
          <IcoEdit className={`${style.edit}`} onClick={() => setShowOption(true)}/>
          {showOption &&
          <>
              <div ref={backdropRef} className={`${style.buttonMenu}`}  >
                <div className={`${style.editButton}`} onClick={() => navigate('/clients/'+id)}>Edit Client</div>
                <div className={`${style.addButton}`} onClick={handlePopupTask} >Add Task</div>
              </div>
          </>
        }
          <Popup show={showPopup} onClose={handlePopupTask}>
            <TaskForm taskable={{id: client.id.toString(),name:client.name,type:client.type}} closePopup={handlePopupTask}/>
          </Popup>
          
        </div>
        <div style={{display:'inline-flex'}}>
          <div className={`${style.picture}`}>
            <div className={`${style.bg_pic}`}>
              <IcoPic className={`${style.pic}`} />
              <div>No logo yet</div>
            </div>
          </div>
          <div className={`${style.data}`}>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Client Name</div>
              <div className={`${style.info}`}>{client?.name}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Subscription</div>
              <div className={`${style.info}`}>{client?.subscription}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Company Website</div>
              <div className={`${style.info}`}>{client?.website}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>ICE</div>
              <div className={`${style.info}`}>{client?.ice}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Manager</div>
              <div className={`${style.info}`}>{client?.manager?.name}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Email Address</div>
              <div className={`${style.info}`}>{client?.email}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Contract</div>
              <div className={`${style.download}`}>
                <div className={`${style.pdf}`}>Contract.pdf</div>
              </div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Status</div>
              <Tag name={client?.status}/>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Phone number</div>
              <div className={`${style.info}`}>{client?.phone}</div>
            </div>
          </div>
        </div>
    </>
  );
}