import React from "react";
import style from "./StatusMenu.module.css";
import styleTag from "assets/css/TagStyle.module.css";
import { Account, Client, Id, Status, User } from "types";
import axiosClient from "axios-client";
import { useStateContext } from "context/ContextProvider";

interface StatusMenuProps {
    object: Account | Client | User, // the ID of the object
    showMenu: boolean,
    onClose:() => void,
    updateStatus: (objectID:Id, newStatus:Status)=>void,
}

const StatusMenu: React.FC<StatusMenuProps> = ( props ) => {

    const { setNotification } = useStateContext();

    if(!props.object){
        return <></>;
    }

    const handleUpdateStatus = (newStatus : Status) => {
        axiosClient.patch(`/${props.object.type}s/${props.object.id}/status`, {status:newStatus})
        .then(({ data }) => {
            setNotification('Status was successfully updated','success');
            props.updateStatus(props.object.id,newStatus);
        })
        .catch(({ errors }) => {
            setNotification('There was a problem','warning');
            console.log(errors);
        })
    }

    

    if (!props.showMenu) {
        return null;
    }

    return (
        <>
        <div className={`${style.menu}`} >
            <button className={`${style.option} ${styleTag.active}`} onClick={()=> handleUpdateStatus(Status.Active)}>
                Active
            </button>
            <button className={`${style.option} ${styleTag.blocked}`} onClick={() => handleUpdateStatus(Status.Blocked)}>
            Blocked
            </button>
            <button className={`${style.option} ${styleTag.inactive}`} onClick={() => handleUpdateStatus(Status.Inactive)}>
                Inactive
            </button>
            <button className={`${style.option} ${styleTag.pending}`} onClick={() => handleUpdateStatus(Status.Pending)}>
                Pending
            </button>
            <button className={`${style.option} ${styleTag.suspended}`} onClick={() => handleUpdateStatus(Status.Suspended)}>
                Suspended
            </button>
            
        </div>
        <div className={`${style.menu_close}`} onClick={props.onClose}/>
        </>
        
    );
};

export default StatusMenu;