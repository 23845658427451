import { Routes, Route } from "react-router-dom"
import Users from "./index/Users";
import UserForm from "./form/UserForm";
import { useStateContext } from "context/ContextProvider";
import { Role } from "types";


const ClientsRoutes = () => {
  const { user } = useStateContext();

  return (
    <Routes>
      
        <Route path='/'>
          <Route index element={<Users />} />
          <Route path='new' element={<UserForm key='accountCreate' />} />
          <Route path=':id' element={<UserForm key='accountUpdate' />} />
        </Route>
      
    </Routes>
  );
};

export default ClientsRoutes
