import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import style from 'assets/css/View.module.css'
import { ReactComponent as IcoEdit } from 'assets/icons/edit.svg';
import { Task } from 'types'
import { useStateContext } from "context/ContextProvider";
import axiosClient from "axios-client";


export default function TaskView() {

  const { id } = useParams();
  const navigate = useNavigate();

  const { setNotification } = useStateContext();

  
  const [task, setTask] = useState<Task>()
  useEffect(()=>{
    axiosClient.get(`/tasks/${id}`)
        .then(({ data }) => {
          setTask({...data});
        })
        .catch((err) => {
          const response = err.response.data;
          setNotification(response.message,'warning');
        })
  },[]);

  if(!task) return <></>;

  return (
    <>
        <div className={`${style.title}`}>
          <div>Task details</div>
          <IcoEdit className={`${style.edit}`} onClick={() => navigate('/tasks/'+id)}/>
        </div>
        <div className={`${style.data}`} key={task?.id}>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Title</div>
              <div className={`${style.info}`}>{task?.title}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>TaskID</div>
              <span className={`${style.info}`}>{task?.task_id}</span>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>priority</div>
              <div className={`${style.info}`}>{task?.priority}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Progress</div>
              <div className={`${style.info}`}>{task?.progress}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Tags</div>
              <div className={`${style.info}`}></div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Due date</div>
              <div className={`${style.info}`}>{task?.duedate}</div>
            </div>
            
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Description</div>
              <div className={`${style.info}`}>{task?.desc}</div>
            </div>
            
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Taskable</div>
              <div className={`${style.info}`}>{task?.taskable?.name}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Assignable</div>
              <div className={`${style.info}`}>{task?.assignable?.name}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>File</div>
              <div className={`${style.download}`}>
                <a href={task?.fileURL} className={`${style.pdf}`}>Download</a>
              </div>
            </div>
        </div>
    </>
  );
}