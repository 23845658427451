import React from 'react'
import {  NavLink } from 'react-router-dom'
import { ReactComponent as IcoDM } from 'assets/icons/Logo.svg'
import style from './Auth.module.css'

//import { useStateContext } from 'src/context/ContextProvider'
//import axiosClient from 'src/axios-client'


let message = 'You should receive an email shortly with further instructions.';

const Congratulation = () => {

  //const {setUser, setToken} = useStateContext();
  //const [errors, setErrors] = useState(null);





  return (
    <div className={`${style.form}`}>
      <IcoDM className={`${style.logo}`} />
      <div className={`${style.congrats}`}>
        Congratulations
      </div>
      <div className={``} style={{ display: message === "" ? 'none' : '' }}>
        { message }
      </div>

      <NavLink to='/' className={`${style.submit}`}>
        Start Now!
      </NavLink>
    </div>
  )
  
}

export default Congratulation
