export type Id = string | number;


// Kanban Types
export type Column = {
  id: Id;
  title: string;
};

// Enumerations
export enum Subscription {
  Monthly='monthly',
  Free='free',
  Unpaid='unpaid',
  Canceled='canceled',
}

export enum Status {
  Inactive = 'inactive',
  Suspended = 'suspended',
  Active= 'active',
  Pending = 'pending', 
  Blocked= 'blocked',
}

export enum Priority {
  Low='low',
  Medium='medium',
  High='high',
  Critical='critical',
  Late='late',
}

export enum Platform {
  Bing='bing',
  DV360='dv360',
  Google='google',
  Meta='meta',
  Snap='snapchat',
  Taboola='taboola',
  Tiktok='tiktok', 
  Zemanta='zemanta',
}

export enum Role{
  Admin='Admin',
  Manager='Manager',
  Team='Associate',
  Advisor='Advisor',
  User='User',
}

export enum Progress{
  Archived='archived',
  New='new',
  Unassigned='unassigned',
  InProgress='inprogress',
  Done='done',
  OnHold='onhold',
  Canceled='canceled',
}



// My Types
export interface Account {
  id: Id,
  client?: Client,
  email: string,
  username: string,
  password: string,
  website: string,
  phone: string,
  status: Status,
  country: string,
  proxy: string,
  commission: number,
  sharedon: string,
  note: string,
  platform: Platform,
  apikey: string,
  type: 'account',
};

export interface Client {
  id: Id,
  name: string,
  phone : string,
  email: string,
  website: string,
  status: Status,
  contract?: string,
  subscription: Subscription,
  subs_date: string,
  ice : string,
  manager: { id: Id, name: string},
  type: 'client'
};

export interface Task {
  id: Id,
  task_id: string,
  title: string,
  priority:Priority,
  tags?:string[],
  desc:string,
  file?: File,
  fileURL?:string,
  duedate:string,
  progress:Progress,
  taskable?:{id:Id, name:string,type:string},
  assignable?:{id:Id, name:string,type:string},
  status?:Status,
  type:'task',
};

export interface User {
  id: Id,
  firstname: string,
  lastname: string,
  email: string,
  roles: Role[],
  status: Status,
  create_at?:string,
  type:'user',
}

// Init objects
export const initClient = {
  id: '',
  name: '',
  phone : '',
  email: '',
  website: '',
  status: Status.Pending,
  contract: '',
  subscription: '',
  subs_date: '',
  ice : '',
  manager: { id: '', name: ''},
  type: 'client'
};

export const initUser = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  roles: [Role.User],
  status: Status.Pending,
  create_at:'',
  type:'user',
}

export const initAccount = {
  id: '',
  client: initClient,
  email: '',
  username: '',
  password: '',
  website: '',
  phone: '',
  status: Status.Pending,
  country: '',
  proxy: '',
  commission: 0,
  sharedon: '',
  note: '',
  platform: '' ,
  apikey: '',
  type: 'account',
};

export const initTask ={
  id: '',
  task_id: '',
  title: '',
  priority:Priority.Medium,
  tags:['test'],
  desc:'',
  duedate:'',
  file: null as File | null,
  fileURL: '',
  progress:Progress.Unassigned,
  taskable:{id:'', name:'', type:''},
  assignable:{id:'', name:'', type:''},
  type:'task',
};