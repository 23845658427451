import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { RouterProvider } from 'react-router-dom'
import routes from './routes'
import { ContextProvider } from './context/ContextProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <ContextProvider>
      <RouterProvider router={routes} />
    </ContextProvider>

  </Provider>
  </React.StrictMode>
);
