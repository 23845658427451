import React, { useEffect } from 'react'
import { Sidebar, Header } from '../components/index'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from 'context/ContextProvider'
import axiosClient from 'axios-client'
import './layout.css'
import Alert from 'components/Alert'

const DefaultLayout: React.FC = () => {

  const {user, token, notification, setUser } = useStateContext();
  

  useEffect(()=>{
    axiosClient.get('/user')
    .then(({data})=>{
      setUser(data);  
    })
  },[]);

  if(!token){
    return <Navigate to='/login'/>
  }

  if(user===null){return <></>;}

  

  return (
    <>
    
      <Header/>
      <div>
        <Sidebar/>
        <div className="main">
          <div className="box">
            <Outlet />
          </div>
        </div>
      </div>
      
      {(notification?.text !== '' && notification) &&
        <Alert text={notification.text} type={notification.type}/>
      }
    </>
  )
}

export default DefaultLayout
