import React from "react";
import style from "./Alert.module.css";
import { ReactComponent as IcoClose } from 'assets/icons/close.svg'
import { useStateContext } from "context/ContextProvider";

interface AlertProps {
  text: string,
  type:string
}

const Alert: React.FC<AlertProps> = (props) => {
  const { setNotification } = useStateContext();
    
  let typeClass ='';

  switch (props.type) {
    case 'success':
      typeClass = style.success;
      break;
    case 'info':
      typeClass = style.info;
      break;
    case 'warning':
      typeClass = style.warning;
      break;
  }
  return (
    <>
    <div className={`${style.alert} ${typeClass}`} >
        <div>
        {props.text}
        </div>
        
        <IcoClose className={`${style.close}`} onClick={()=> setNotification('','')}/>
    </div>
    </>
  );
};

export default Alert;