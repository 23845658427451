import React from 'react'
import style from './Pagination.module.css'

interface PaginationProps {
    currentPage: number,
    totalPages: number,
    onPageChange: (page: number) => void;
}

function Pagination(props: PaginationProps) {

    const handleClick = (pageNumber: number) => {
        props.onPageChange(pageNumber);
    };

    return (
        <nav className={`${style.pagination}`}>

            

                <button

                    className={`${style.pageItem}`}
                    onClick={() => handleClick(props.currentPage - 1)}
                    disabled={props.currentPage === 1}>
                    Prev
                </button>

            {Array.from({ length: props.totalPages }, (_, index) => (

                <button key={index + 1} className={`${style.pageItem}`} onClick={() => handleClick(index + 1)}>
                    {index + 1}
                </button>

            ))}
                <button
                    className={`${style.pageItem}`}
                    onClick={() => handleClick(props.currentPage + 1)}
                    disabled={props.currentPage === props.totalPages}>
                    Next
                </button>
        </nav>
    )
}

export default Pagination