import React, { useEffect, useState } from 'react'
import axiosClient from 'axios-client';
import AddButton from 'components/Buttons/Add/AddButton'
import style from 'assets/css/Table.module.css'
import Filter from 'components/Filter'
import { Id, Role, Status, User } from 'types'
import { useToggleMenu } from 'hooks/useToggleMenu';
import { useNavigate } from 'react-router-dom';
import Tag from 'components/Buttons/Tag';
import StatusMenu from 'components/Buttons/StatusMenu';
import Pagination from 'components/Pagination/Pagination';
import usePagination from 'hooks/usePagination';

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, toggleMenu, closeMenu } = useToggleMenu();
  const navigate = useNavigate();

  // Get enums
  const roles = Object.values(Role);
  const statusVal = Object.values(Status);
  // Datalist suggestions
  const suggestions: (Role | Status)[] = [...statusVal, ...roles];

  // Pagination Hook
  const itemsPerPage = 10;
  const { currentPage, totalPages, handlePageChange, getCurrentPageData } = usePagination(users, itemsPerPage);

  
  useEffect(() => {
    getUsers([]);
  }, []);

  const getUsers = (filters:string[]) => {
    setLoading(true);

    axiosClient.get('/users',{
      params:{
        'filters':filters
      }})
      .then(({ data }) => {
        setLoading(false);
        setUsers(data.data);
      })
      .catch(({ errors }) => {
        setLoading(false);
      })
  }

  const updateObjectStatus = (objectId:Id, newStatus:Status) => {
    // Find the account by its ID
    const updatedUsers = users?.map((user) => {
      if (user.id === objectId) {
        // Update the status of the account with the provided ID
        return { ...user, status: newStatus };
      }
      return user;
    });

    // Update the state with the modified accounts array
    setUsers(updatedUsers);
  };

  return (
    <>
      <div className={style.page_title}>
        <div className={style.label}>
          Users
        </div>
        <div className={style.count}>{users?.length} Users</div>
        {(users && users.length > 0) &&
          <div style={{ marginLeft: 'auto' }}>
            <AddButton text='User' />
          </div>
        }
      </div>
      <div className={style.box}>

        <Filter setFilters={getUsers} suggestions={suggestions.sort()}/>

        <table className={style.table} style={{ border: !users?.length ? '0px' : '' }}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
            </tr>
          </thead>
          
          <tbody>
            {getCurrentPageData()?.map((user: User) => (
              <tr key={user.id} onDoubleClick={()=>navigate(user.id.toString())}>
              <td>{user.firstname} {user.lastname}</td>
              <td>{user.email}</td>
              <td>
                {user.roles[0]}
              </td>
              <td onClick={(event) => toggleMenu(user.id, event)}>
              <Tag name={user.status}/>
                {isOpen === user.id && (
                  <StatusMenu showMenu={true} onClose={closeMenu} object={user} updateStatus={updateObjectStatus}/>
                )}
              </td>
            </tr>
            ))}
          </tbody>
          

        </table>

        {users?.length === 0 && (
          <div className={style.no_record}>
            <div className={style.text_wrapper}>No user yet! Please click on the button bellow to add new user.</div>
            <div style={{margin:'auto'}}>
              <AddButton text='User' />
            </div>
          </div>
        )}
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}/>
      </div>
    </>

  )
}

export default Users