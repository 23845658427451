import React, { useState } from 'react';
import style from './Sidebar.module.css';
import { ReactComponent as IcoAccounts } from 'assets/icons/accounts.svg';
import { ReactComponent as IcoDashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as IcoClients } from 'assets/icons/clients.svg';
import { ReactComponent as IcoUsers } from 'assets/icons/users.svg';
import { ReactComponent as IcoTasks } from 'assets/icons/tasks.svg';
import { ReactComponent as IcoCloseArrow } from 'assets/icons/close-arrow.svg';
import { NavLink } from 'react-router-dom';
import { useStateContext } from 'context/ContextProvider';
import { Role } from 'types';

interface SidebarProps {
  // Define the props here, if any
}

const Sidebar: React.FC<SidebarProps> = (props) => {

  const [isNarrow, setNarrow] = useState(false);
  const { user } = useStateContext();


  const handleToggle = () => {
    setNarrow(!isNarrow);
  };

  const showClients = user?.roles.some(
    (role) => role === Role.Admin || role === Role.Manager || role === Role.Team
  );

  const showUsers = user?.roles.some(
    (role) => role === Role.Admin
  );

  const showAccounts = user?.roles.some(
    (role) => role === Role.Admin || role === Role.Team || role ===Role.User
  );

  return (

    <div className={`${style.sidebar_content} ${isNarrow ? `${style.narrow}` : ''}`}>

      <NavLink to="/" className={({ isActive }) => (isActive ? `${style.active}` : "")} end>
        <IcoDashboard fill='black' />
        <div className={`${style.text_wrapper}`}>Dashboard</div>
      </NavLink>

      {showClients &&
        <NavLink to="/clients" className={({ isActive }) => (isActive ? `${style.active}` : "")}>
          <IcoClients fill='black' />
          <div className={`${style.text_wrapper}`}>Clients</div>
        </NavLink>
      }

      {showAccounts &&
        <NavLink to="/accounts" className={({ isActive }) => (isActive ? `${style.active}` : "")} >
          <IcoAccounts fill='black' />
          <div className={`${style.text_wrapper}`}>Accounts</div>
        </NavLink>
      }

      {showUsers &&
        <NavLink to="/users" className={({ isActive }) => (isActive ? `${style.active}` : "")} >
          <IcoUsers fill='black' />
          <div className={`${style.text_wrapper}`}>Users</div>
        </NavLink>
      }




      <NavLink to="/tasks" className={({ isActive }) => (isActive ? `${style.active}` : "")} >
        <IcoTasks fill='black' />
        <div className={`${style.text_wrapper}`}>Tasks</div>
      </NavLink>

      <IcoCloseArrow className={`${style.close_arrow}`} onClick={handleToggle} />
    </div>
  );
};

export default Sidebar;