import React, { useEffect, useState } from 'react'
import axiosClient from 'axios-client';
//import { Link } from 'react-router-dom';
import { useStateContext } from 'context/ContextProvider';
import style from 'assets/css/Table.module.css'
import Filter from 'components/Filter'
import AddButton from 'components/Buttons/Add/AddButton';
import Tag from 'components/Buttons/Tag';
import StatusMenu from 'components/Buttons/StatusMenu';
import { Account, Id, Platform, Status } from 'types';
import { useToggleMenu } from 'hooks/useToggleMenu';
import { useNavigate } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import usePagination from 'hooks/usePagination';

const Accounts: React.FC = () => {

  const [accounts, setAccounts] = useState<Account[]>([]);
  const { setNotification } = useStateContext();
  const { isOpen, toggleMenu, closeMenu } = useToggleMenu();
  const navigate = useNavigate();

  // Get enums
  const platforms = Object.values(Platform);
  const statusVal = Object.values(Status);
  // Datalist suggestions
  const suggestions: (Platform | Status)[] = [...statusVal, ...platforms];

  // Pagination Hook
  const itemsPerPage = 10;
  const { currentPage, totalPages, handlePageChange, getCurrentPageData } = usePagination(accounts, itemsPerPage);

  useEffect(() => {
    getAccounts([]);
  },[]);

  const getAccounts = (filters:string[]) => {

    axiosClient.get('/accounts',{
      params:{
        'filters':filters
      }})
      .then(({ data }) => {
        
        setAccounts(data.data);
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          setNotification(response.message,'warning');
        }
      })
  }  

  const updateObjectStatus = (objectId:Id, newStatus:Status) => {
    // Find the account by its ID
    const updatedAccounts = accounts?.map((account) => {
      if (account.id === objectId) {
        // Update the status of the account with the provided ID
        return { ...account, status: newStatus };
      }
      return account;
    });

    // Update the state with the modified accounts array
    setAccounts(updatedAccounts);
  };

  return (
    <>
      <div className={style.page_title}>
        <div className={style.label}>
          Accounts
        </div>
        <div className={style.count}>{accounts?.length} Accounts</div>
        { (accounts && accounts.length > 0) &&
          <div style={{ marginLeft: 'auto' }}>
            <AddButton text='Account' />
          </div>
        }
        
      </div>
      <div className={style.box}>

      <Filter setFilters={(getAccounts)} suggestions={suggestions.sort()} />

        <table className={style.table} style={{ border: !accounts?.length ? '0px' : '' }}>
          <thead>
            <tr>
              <th>Client</th>
              <th>Platform</th>
              <th>Email</th>
              <th>Username</th>
              <th>Country</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {getCurrentPageData()?.map((account) => (
              <tr key={account.id} onDoubleClick={()=>navigate('view/'+account.id)}>
              <td>{account.client?.name}</td>
              <td className={style.subscrition}>
              <Tag name={account.platform}/>
              </td>
      
              <td>
                {account.email}
              </td>
              <td>
                {account.username}
              </td>
              <td>
                {account.country}
              </td>
              <td onClick={(event) => toggleMenu(account.id, event)}>
                <Tag name={account.status}/>
                {isOpen === account.id && (
                    <StatusMenu showMenu={true} onClose={closeMenu} object={account} updateStatus={updateObjectStatus}/>
                  )}
              </td>
            </tr>
            ))}
          </tbody>
        </table>

        {!accounts?.length && (
          <div className={style.no_record}>
            <div className={style.text_wrapper}>No account yet! Please click on the button bellow to add new account.</div>
            <AddButton text='Account'/>
          </div>
        )}
        
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}/>
      </div>
    </>

  )
}

export default Accounts