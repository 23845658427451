import { useNavigate, useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { Status, Platform, initAccount, Client } from 'types'
import style from 'assets/css/Form.module.css'
import { useStateContext } from "context/ContextProvider";
import axiosClient from "axios-client";


export default function AccountForm() {

  const navigate = useNavigate();
  let { id } = useParams();
  const [account, setAccount] = useState(initAccount);
  const [clients, setClients] = useState<Client[]>();

  //const [errors, setErrors] = useState(null);
  //const [loading, setLoading] = useState(false);
  const { setNotification } = useStateContext();

  // Get enums
  const platforms = Object.values(Platform);
  const statusVal = Object.values(Status);

  useEffect(() => {
    getClients();
    if (id) {
      //setLoading(true)
      axiosClient.get(`/accounts/${id}`)
        .then(({ data }) => {
          //setLoading(false);
          setAccount({...data});
        })
        .catch(() => {
          //setLoading(false)
        })
    }
  },[]);

  const getClients = () => {
    //setLoading(true);

    axiosClient.get('/clients')
      .then(({ data }) => {
        //setLoading(false);
        setClients(data.data);
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          setNotification(response.message,'warning');
        }
      })
  }



  const onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault();

    if (account.id) {
      axiosClient.put(`/accounts/${account.id}`, account)
        .then(() => {
          setNotification('Account was successfully updated','success');
        })
        .catch(err => {
          const response = err.response;
          if (response) {
            setNotification(response.message,'warning');
          }
        })
    } else {
      axiosClient.post('/accounts', account)
        .then(() => {
          setNotification('Account was successfully created','success');
        })
        .catch(err => {
          const response = err.response.data;
          if (response ) {
            setNotification(response.message,'warning');
          }
        })
    }
  }


  return (
    <>
      <form className={`${style.form}`} onSubmit={onSubmit}>
        <div className={`${style.title}`}>
          {account.id ? `Update account: ${account.username}` : 'New account'}
        </div>

        <div className={`${style.container}`}>
          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="name">Client Name</label>
            <select name="name" id="name" value={account.client?.id} onChange={ev => setAccount({ ...account, client: { ...account.client, id: ev.target.value } })}>
              <option value=''>Select a client</option>
              {clients?.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="platform">Platform *</label>
            <select name="platform" id="platform" value={account.platform.toString()} onChange={ev => setAccount({ ...account, platform: ev.target.value as Platform })} required>
              <option value=''>Select a platform</option>
              {platforms.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="sharedon">Shared On</label>
            <input className={`${style.input}`} type="date" name="sharedon" id="sharedon" value={account.sharedon} onChange={ev => setAccount({ ...account, sharedon: ev.target.value })} readOnly />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="email">Email *</label>
            <input className={`${style.input}`} type="email" name="email" id="email" value={account.email} onChange={ev => setAccount({ ...account, email: ev.target.value })} required />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="username">Account ID *</label>
            <input className={`${style.input}`} type="text" name="username" id="username" value={account.username} onChange={ev => setAccount({ ...account, username: ev.target.value })} />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="password">Password</label>
            <input className={`${style.input}`} type="text" name="password" id="password" value={account.password} onChange={ev => setAccount({ ...account, password: ev.target.value })} />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="phone">Phone number</label>
            <input className={`${style.input}`} type="tel" name="phone" id="phone" value={account.phone} onChange={ev => setAccount({ ...account, phone: ev.target.value })} />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="website">Website *</label>
            <input className={`${style.input}`} type="url" name="website" id="website" value={account.website} onChange={ev => setAccount({ ...account, website: ev.target.value })} required />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="commission">Commission</label>
            <input className={`${style.input}`} type="number" name="commission" id="commission" value={account.commission} onChange={ev => setAccount({ ...account, commission: parseFloat(ev.target.value) })} />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="api">API Key</label>
            <input className={`${style.input}`} type="text" name="api" id="api" value={account.apikey} onChange={ev => setAccount({ ...account, apikey: ev.target.value })} />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="country">Country *</label>
            <input className={`${style.input}`} type="text" name="country" id="country" value={account.country} onChange={ev => setAccount({ ...account, country: ev.target.value })} />
          </div>

          <div className={`${style.input_container}`}>
            <label className={`${style.label}`} htmlFor="proxy">Proxy</label>
            <input className={`${style.input}`} type="text" name="proxy" id="proxy" value={account.proxy} onChange={ev => setAccount({ ...account, proxy: ev.target.value })} />
          </div>
        </div>

        <div className={`${style.input_container}`}>
          <label className={`${style.label}`} htmlFor="platform">Status *</label>
          <select name="status" id="status" value={account.status.toString()} onChange={ev => setAccount({ ...account, status: ev.target.value as Status })} required>
            <option value=''>Select a platform</option>
            {statusVal.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className={`${style.button} ${style.submit}`}>Save</button>
        <button type='reset' className={`${style.button} ${style.cancel}`} onClick={() => navigate(-1)}>
          Cancel
        </button>


      </form>

    </>
  )
}