import React from 'react';
import CSS from 'csstype';

interface PopupProps {
  children: React.ReactNode;
  onClose: () => void; // Optional callback for closing the popup
  show: boolean;
}

const Popup: React.FC<PopupProps> = ({ children, onClose, show }) => {

  const containerStyles: CSS.Properties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 700,
  };

  const contentStyles: CSS.Properties = {
    width: '50%',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose(); // Call provided onClose callback if set
    }
  };

  return (show) ? (
    <div style={containerStyles} onClick={handleBackdropClick}>
      <div style={contentStyles}>
        {children}
      </div>
    </div>
  ) : <></>;
};

export default Popup;
