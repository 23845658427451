import { createBrowserRouter } from "react-router-dom"
import DefaultLayout from "layout/DefaultLayout"
import GuestLayout from "layout/GuestLayout"
import Page404 from "views/errors/Page404"
import Page500 from "views/errors/Page500"
import ClientsRoutes from 'views/clients/routes'
import AccountsRoutes from 'views/accounts/routes'
import UsersRoutes from 'views/users/routes'
import TasksRoutes from 'views/tasks/routes'
import Login from 'views/auth/Login'
import Forgot from 'views/auth/ForgotPW'
import ChangePW from 'views/auth/ChangePW'
import Register from 'views/auth/Register'
import Congratulation from 'views/auth/Congratulation'
import Dashboard from "views/dashboard/Dashboard"



const routes = createBrowserRouter([
  { 
    path: '/', 
    id: 'Home',
    element: <DefaultLayout />,
    children:[
      {
        path:'',
        element:<Dashboard/>
      },
      { 
        path: '/clients/*',
        element: <ClientsRoutes />
      },
      { 
        path: '/accounts/*',
        element: <AccountsRoutes />
      },
      { 
        path: '/users/*',
        element: <UsersRoutes />
      },
      { 
        path: '/tasks/*',
        element: <TasksRoutes />
      },
    ]
  },
  {
    path:'/',
    id: 'Guest',
    element: <GuestLayout/>,
    children:[
      {
        path:'login',
        id: 'Login',
        element:<Login/>
      },
      {
        path:'forgot',
        id: 'Forgot',
        element:<Forgot/>
      },
      {
        path:'changepassword',
        id: 'ChangePW',
        element:<ChangePW/>
      },
      {
        path:'register',
        id: 'Register',
        element:<Register/>
      },
      {
        path:'congratulations',
        id: 'Congratulations',
        element:<Congratulation/>
      },
      
      
    ]
  },
  { 
    path: '/500', 
    id: 'Error 500', 
    element: <Page500/> 
  },
  { 
    path: '*', 
    id: 'Error 404', 
    element: <Page404/> 
  },
])

export default routes
