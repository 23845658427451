import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from 'axios';

const axiosClient: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`
});

// Define the request interceptor
axiosClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError): Promise<InternalAxiosRequestConfig> => {
    return Promise.reject(error);
  }
);

// Define the response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const { response } = error;

    if (response) {
      if (response.status === 401) {
        localStorage.removeItem('ACCESS_TOKEN');
        // Redirect to login page or initiate reauthentication
        // Example: window.location.href = '/login';
      } else if (response.status === 404) {
        // Handle Not Found (404) error
        // Example: display a message or redirect to a not found page
      }
    } else {
      // Handle network errors or unexpected responses
      // Example: display a generic error message
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
