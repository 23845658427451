import KanbanBoard from 'components/Kanban/KanbanBoard'
import React, { useState, useEffect } from 'react'
import style from './Tasks.module.css'
import Filter from 'components/Filter'
import { Platform, Priority, Progress, Task } from 'types'
import axiosClient from 'axios-client';
import { useStateContext } from 'context/ContextProvider'
import Tag from 'components/Buttons/Tag'
import AddButton from 'components/Buttons/Add/AddButton'
import Pagination from 'components/Pagination/Pagination'
import usePagination from 'hooks/usePagination'
import { DatePicker } from 'antd'
import type { DatePickerProps } from 'antd'

const Tasks: React.FC = () => {
  
  const [tasks, setTasks] = useState<Task[]>([]);
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dueDateFilter, setDueDateFilter] = useState('')
  const { setNotification } = useStateContext();

  // Get enums
  const platforms = Object.values(Platform);
  const progress = Object.values(Progress);
  const priority = Object.values(Priority);
  // Datalist suggestions
  const suggestions: (Platform | Progress | Priority)[] = [...progress, ...platforms, ...priority];

  // Pagination Hook
  const itemsPerPage = 10;
  const { currentPage, totalPages, handlePageChange, getCurrentPageData } = usePagination(tasks ? tasks : [], itemsPerPage);

  useEffect(() => {
    getTasks([]);
  }, []);

  const getTasks = (filters:string[]) => {
    setLoading(true)

    axiosClient.get('/tasks',{
      params:{
        'filters':filters
      }})
      .then(({ data }) => {
        setTasks(data.data);
        setLoading(false);
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          setNotification(response.message, 'warning');
        }
      })
  }

  const handleDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
    setDueDateFilter(dateString as string);
  };

  return (
    <>
      <div className={style.page_title}>
        <div className={style.label}>
          Tasks
        </div>
        <div className={style.count}>{tasks?.length} tasks</div>
        <div className={`${style.tabs} ${showList ? style.activeList : style.activeKan}`}>
          <div className={`${style.tab}`} onClick={() => setShowList(false)}>Kanban</div>
          <div className={`${style.tab}`} onClick={() => setShowList(true)}>List</div>
        </div>
        {(tasks && tasks.length > 0) &&
          <div style={{ marginLeft: 'auto' }}>
            <AddButton text='Task' />
          </div>
        }
      </div>
      
      <Filter setFilters={getTasks} suggestions={suggestions.sort()}/>

      <DatePicker onChange={handleDateChange}/>
      
      {loading && <p>Loading...</p>}
      {!loading &&
        <>
          {(!showList && tasks) &&
            <div className={`${style.kanban}`}>
              <KanbanBoard tasks={tasks} />
            </div>

          }
          {showList &&
            <>
              <table className={style.table} style={{ border: !tasks?.length ? '0px' : '' }}>
                <thead>
                  <tr>
                    <th>Associated to</th>
                    <th>Title</th>
                    <th>Ticket N°</th>
                    <th>Tags</th>
                    <th>Priority</th>
                    <th>Due date</th>
                    <th>Assigned to</th>
                    <th>Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentPageData()?.map((task) => (
                    <tr key={task.id}>
                      <td className={style.name}>{(task.taskable?.type === 'account') ? '#' + task.taskable?.name.split('@')[0] : '@' + task.taskable?.name}</td>

                      <td className={style.platform}>
                        {task.title}
                      </td>
                      <td>
                        {task.task_id}
                      </td>
                      <td>{task.priority}</td>
                      <td>
                        {(task.tags && task.tags?.length > 0) && (
                          <>
                            {task.tags.map((tag) => (
                              <Tag name={tag} key={tag} />
                            ))}
                          </>
                        )}
                      </td>
                      <td>{task.duedate}</td>
                      <td>{task.assignable?.name}</td>
                      <td>{task.progress}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            </>
          }
        </>

      }


    </>
  )
}

export default Tasks;