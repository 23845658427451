import {createContext, useContext, useState} from 'react';
import { User } from 'types';

interface StateContextProps {
  user: User | null;
  setUser: (user:User|null) => void;
  token: string | null;
  setToken: (token: string|null) => void;
  notification: {text:string, type:string} | null;
  setNotification: (message: string, type:string) => void;
}

// Create a new context called StateContext with default values for currentUser, token, and notification
const StateContext = createContext<StateContextProps>({
  user: null,
  setUser: () => {},
  token: null,
  setToken: () => {},
  notification: {text:'',type:''},
  setNotification: () => {}
});

// Define a ContextProvider component that wraps its children with the context provider
export const ContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, _setUser] = useState<User | null>(null);
  const [token, _setToken] = useState<string | null>(localStorage.getItem('ACCESS_TOKEN'));
  const [notification, _setNotification] = useState({text:'',type:''});

  const setToken = (token: string|null) => {
    _setToken(token);

    if (token) {
      localStorage.setItem('ACCESS_TOKEN', token);
    } else {
      localStorage.removeItem('ACCESS_TOKEN');
    }
  };

  const setUser = (user: User | null):void => {
    if (user && user.roles) {
      user.roles.sort((a, b) => a.localeCompare(b));
    }
    
    _setUser(user);
  };

  const setNotification = (message: string,type:string) => {
    _setNotification({text:message,type:type});

    setTimeout(() => {
      _setNotification({text:'',type:''});
    }, 5000);
  };

  return (
    <StateContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        notification,
        setNotification,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContextProps => useContext(StateContext);