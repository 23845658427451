import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import style from './Header.module.css';
import { ReactComponent as DigestMediaLogo } from "assets/icons/Logo.svg";
import { ReactComponent as IcoNotifications } from "assets/icons/notifications.svg";
import { ReactComponent as IcoOptions } from "assets/icons/options.svg";
import { ReactComponent as IcoProfil } from "assets/icons/profil.svg";
import { useStateContext } from 'context/ContextProvider';
import { Navigate } from 'react-router-dom';
import axiosClient from 'axios-client'

interface HeaderProps {
  
}

const Header: React.FC<HeaderProps> = (HeaderProps) => {
  
  const backdropRef = useRef<HTMLDivElement>(null);

  const {user, setUser, setToken}= useStateContext();
  const [show, setShow] = useState(false);

  const onLogout = (ev: SyntheticEvent) => {
    ev.preventDefault()

    axiosClient.post('/logout')
      .then(() => {
        setUser(null);
        setToken(null);
      })
  }

  const handleClickOutside = (event: MouseEvent ) => {
    if (backdropRef.current && !backdropRef.current.contains(event.target as HTMLDivElement)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [backdropRef]);

  if(!user){
    return <Navigate to="/login" />;
  }

  return (
    <div className={`${style.header}`}>
      <div className={`${style.logo}`}>
        <DigestMediaLogo className={`${style.dm_logo}`} />
      </div>
      
      <div className={`${style.notif}`}>
          <IcoNotifications className={`${style.notification}`} />
      </div>
      
      <div className={`${style.profile_information}`}>
        <div className={`${style.info}`}>
          <div className={`${style.ellipse}`}>
              <IcoProfil />
            </div>
          <div className={`${style.name_role}`}>
            <div className={`${style.name}`}>{ user.firstname } { user.lastname }  </div>
            <div className={`${style.role}`}>
            {user.roles[0]}
            </div>
          </div>
        </div>
        <IcoOptions className={`${style.menu_header}`} onClick={()=> setShow(true)}/>
        {show &&
          <>
              <div ref={backdropRef} className={`${style.settings}`}  >
                <div >
                  Account settings
                </div>
                <div onClick={onLogout}>
                  Log out
                </div>
              </div>
          </>
        }
        
      </div>
    </div>
  );
};

export default Header;