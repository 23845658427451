import { Priority, Task } from 'types';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styles from './Kanban.module.css'
import { ReactComponent as IcoOptions } from "assets/icons/options.svg";
import Tag from "components/Buttons/Tag";

interface Props {
  task: Task;
}

function TaskCard({ task }: Props) {

  let prioClass = '';

  switch (task.priority) {
    case Priority.Late:
      prioClass = styles.late;
      break;
    case Priority.High:
      prioClass = styles.high;
      break;
    case Priority.Medium:
      prioClass = styles.medium;
      break;
    case Priority.Low:
      prioClass = styles.low;
      break;
    case Priority.Critical:
      prioClass = styles.critical;
      break;
  }
  
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task,
    }
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={`${styles.card_dragging}`}
      />
    );
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`${styles.card}`}
    >
      <div className={`${styles.row}`}>
        <div className={`${styles.card_title}`}>
          {task.title}
        </div>
        <div>
          <div className={`${styles.prio} ${prioClass}`}></div>
          <IcoOptions className={`${styles.options}`}/>
        </div>
      </div>

      <div className={`${styles.row}`}>
      {(task.tags && task.tags?.length > 0) && (
  <>
    {task.tags.map((c) => (
      <Tag key={c} name={c}/>
    ))}
  </>
)}
      </div>

      <div className={`${styles.row} ${styles.footer}`}>
        <div>{task.duedate}</div>
        <div>{(task.taskable?.type === 'account')? '#'+task.taskable?.name.split('@')[0] : '@'+task.taskable?.name }</div>
      </div>
    </div>
  );
}

export default TaskCard;