import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useStateContext } from 'context/ContextProvider';

const GuestLayout: React.FC = () => {
  const { token } = useStateContext();

  if ( token ) {
    return <Navigate to="/" />;
  } 

  return (
    <div className='guest_body'>
      <Outlet/>
      <div className={`footer`}>
          ©<div className='dm_footer'>Digest Media</div> 2023 . All rights reserved
      </div>
    </div>

  )
}

export default GuestLayout
