import React, { SyntheticEvent } from "react";
import style from "./Tag.module.css";
import tagStyle from "assets/css/TagStyle.module.css";
import { ReactComponent as IcoClose } from 'assets/icons/close.svg'

interface TagProps{
    name?: string,
    deleteTag?:(name:string)=>void;
}

const Tag: React.FC<TagProps> = (props) => {

    if(!props.name){ return <></>}

    let classTag = '';

    switch (props.name.toLowerCase()) {
        case 'active':
            classTag = tagStyle.active;
            break;
        case 'inactive':
            classTag = tagStyle.inactive;
            break;
        case 'blocked':
            classTag = tagStyle.blocked;
            break;
        case 'pending':
                classTag = tagStyle.pending;
                break;
        case 'suspended':
            classTag = tagStyle.suspended;
            break;
        case 'bing':
            classTag = tagStyle.bing;
            break;
        case 'dv360':
            classTag = tagStyle.dv360;
            break;
        case 'google':
            classTag = tagStyle.google;
            break;
        case 'meta':
            classTag = tagStyle.meta;
            break;
        case 'snapchat':
            classTag = tagStyle.snapchat;
            break;
        case 'taboola':
            classTag = tagStyle.taboola;
            break;
        case 'tiktok':
            classTag = tagStyle.tiktok;
            break;
        case 'zemanta':
            classTag = tagStyle.zemanta;
            break;
        default:
            classTag = tagStyle.default;
            break;
    }
     
    const handleDelete = (event: SyntheticEvent) => { 
        if( props.name && props.deleteTag) {
            props.deleteTag(props.name);
        }
    };
  
    return (
        <>
            <div className={`${style.tag} ${classTag}`}>
                <div className={`${style.text_wrapper}`}>{props.name}</div>
                {props.deleteTag &&
                <IcoClose className={`${style.close}`} onClick={handleDelete}/>
                }
                
            </div>
        </>
    );
  };
  
  export default Tag;