import { useState, SyntheticEvent } from 'react';

export const useToggleMenu = () => {
  const [isOpen, setIsOpen] = useState(null);

  const toggleMenu = (index: any, ev?: SyntheticEvent) => {
    if (ev) {
      ev.stopPropagation();
    }

    setIsOpen(index === isOpen ? null : index);
  };

  const closeMenu = () => {
    setIsOpen(null);
  };

  return {
    isOpen,
    toggleMenu,
    closeMenu,
  };
};