import React, { useRef, useState, SyntheticEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as IcoDM } from 'assets/icons/Logo.svg'
import style from './Auth.module.css'
import { useStateContext } from 'context/ContextProvider'
import axiosClient from 'axios-client'

const Login = () => {

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const {setUser, setToken} = useStateContext();
  const [message,setMessage] = useState<string>();


  const onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault();

    const payload = {
      email: emailRef.current?.value,
      password: passwordRef.current?.value,
    }

    axiosClient.post('/login', payload)
      .then(({data}) => {
        // On successful response (status code 2xx), update the user and token state with the data received from the server
        setUser(data.userData);
        setToken(data.token);
      })
      .catch(err => {
        const response = err.response.data;
        if (response) {
          setMessage(response.message)
        }
      })
  }


  return (
    <form className={`${style.form}`}>
      <IcoDM className={`${style.logo}`} />
      <input type='email' placeholder='Email' className={`${style.input}`} ref={emailRef}/>
      <input type='password' placeholder='Password' className={`${style.input}`} ref={passwordRef}/>
      <div className={`${style.row} ${style.message}`} style={{ display: message === "" ? 'none' : '' }}>
      { message }
      </div>
      <div className={`${style.row}`}>
        <input type='checkbox' id='Remember' name='Remember' />
        <label htmlFor="Remember" className={`${style.label}`}>Remember me</label>
        <NavLink to='/' className={`${style.link}`}>Forgot Password?</NavLink>
      </div>
      <input type="submit" value="Login" className={`${style.submit}`} onClick={onSubmit}/>
    </form>
  )
}

export default Login
