import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import style from 'assets/css/View.module.css'
import { ReactComponent as IcoEdit } from 'assets/icons/edit.svg';
import { Account } from 'types'
import { useStateContext } from "context/ContextProvider";
import axiosClient from "axios-client";
import Tag from "components/Buttons/Tag";
import Popup from "components/Popup/Popup";
import TaskForm from "views/tasks/form/TaskForm";


export default function AccountView() {

  let { id } = useParams();
  const navigate = useNavigate();

  const { setNotification } = useStateContext();

  // Button Menu
  const [showOption, setShowOption] = useState(false);
  const backdropRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent ) => {
    if (backdropRef.current && !backdropRef.current.contains(event.target as HTMLDivElement)) {
      setShowOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [backdropRef]);

  // PopUp Form
  const [ showPopup, setShowPopup] = useState(false);
  const handlePopupTask = () => {
    setShowOption(false);
    setShowPopup(!showPopup);
  }

  // Get account from api
  const [account, setAccount] = useState<Account>();
  useEffect(()=>{
    axiosClient.get(`/accounts/${id}`)
        .then(({ data }) => {
          setAccount({...data});
        })
        .catch((err) => {
          const response = err.response.data;
          setNotification(response.message,'warning');
        })
  },[]);
  
  if(!account){
    return <></>
  }

  return (
    <>
        <div className={`${style.title}`}>
          <div>Account details</div>
                    
          <IcoEdit className={`${style.edit}`} onClick={() => setShowOption(true)}/>
          {showOption &&
          <>
              <div ref={backdropRef} className={`${style.buttonMenu}`}  >
                <div className={`${style.editButton}`} onClick={() => navigate('/accounts/'+id)}>Edit Account</div>
                <div className={`${style.addButton}`} onClick={handlePopupTask} >Add Task</div>
                <div className={`${style.addButton}`}>Add Top up</div>
              </div>
          </>
        }
        
        <Popup show={showPopup} onClose={handlePopupTask}>
          <TaskForm taskable={{ id: account.id.toString(), name: account.username, type: account.type }} closePopup={handlePopupTask} />
        </Popup>
        </div>
        <div className={`${style.data}`}>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Client Name</div>
              <div className={`${style.info}`}>{account.client?.name}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Plateform</div>
              <Tag name={account.platform} />
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Country</div>
              <div className={`${style.info}`}>{account.country}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Phone Number</div>
              <div className={`${style.info}`}>{account.phone}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Email</div>
              <div className={`${style.info}`}>{account.email}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Account ID</div>
              <div className={`${style.info}`}>{account.username}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Password</div>
              <div className={`${style.info}`}>{account.password}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Website</div>
              <div className={`${style.info}`}>{account.website}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Shared ON</div>
              <div className={`${style.info}`}>{account.sharedon}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Commission</div>
              <div className={`${style.info}`}>{account.commission}%</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>API Key</div>
              <div className={`${style.info}`}>{account.apikey}</div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Proxy</div>
              <div className={`${style.download}`}>
                <div className={`${style.pdf_name}`}>{account.proxy}</div>
              </div>
            </div>
            <div className={`${style.data_container}`}>
              <div className={`${style.label}`}>Status</div>
              <Tag name={account.status} />
            </div>
        </div>
    </>
  );
}